import React from "react"
import Layout from "../components/layout"
import Priceguarantee from "../components/marketing/priceguarantee"
import SEO from "../components/seo"

const guaranteePage = (props) => (
  <Layout>
    <SEO title="guarantee" />
    <Priceguarantee {...props}/>
  </Layout>
)

export default guaranteePage
