import React, { Component } from "react";
import _ from "lodash";
import moment from 'moment'
import { Tabs, DatePicker, Collapse } from 'antd';
import { navigate } from 'gatsby'
import Geosuggest from 'react-geosuggest'
import axios from 'axios'
import ReactGA from 'react-ga'
import ReactModal from 'react-modal'
import Header from "./header"
import BusinessForm from "./business-form"
import ServicesBanner from "../../images/services.jpg"
import phoneIcon from '../../images/phoneicon.svg'
import ServicesBannerNewyork from "../../images/services-newyork.jpg"
import GarbageTruck from "../../images/garbagetruck.jpg"
import WorkOne from '../../images/work1.svg'
import WorkTwo from '../../images/work2.svg'
import WorkThree from '../../images/work3.svg'
import dumpsterImgOne from '../../images/dumpsterimgone.svg'
import dumpsterImgTwo from '../../images/dumpsterimgtwo.svg'
import containerYard from '../../images/containeryard.svg'
import Containerimg from '../../images/container-img.svg'
import modalcloseIcon from '../../images/cancel.svg'
import ReviewIcon from '../../images/read-review-icon.svg'
import guaranteeBanner from '../../images/guarantee-banner.jpg'
import BackedIcon from '../../images/backed-icon.svg'

const { Panel } = Collapse;
const { TabPane } = Tabs;
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME
const dateFormat = 'MM/DD/YYYY';
const LOGIN_URL = process.env.LOGIN_URL

const weekdays = new Array(7);
  weekdays[0] = "Sunday"
  weekdays[1] = "Monday"
  weekdays[2] = "Tuesday"
  weekdays[3] = "Wednesday"
  weekdays[4] = "Thursday"
  weekdays[5] = "Friday"
  weekdays[6] = "Saturday"

const timeArray =[
  { value: "6 AM - 9 AM", title: "6 AM - 9 AM" },
  { value: "9 AM - 12 PM", title: "9 AM - 12 PM" },
  { value: "12 PM - 3 PM", title: "12 PM - 3 PM" },
  { value: "3 PM - 6 PM", title: "3 PM - 6 PM" },
  { value: "After 6 PM", title: "After 6 PM" },
]

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().subtract(1, 'days')
}

const checkZip = data => {
  return fetch(`${HOSTNAME}/api/sessions/checkzip?zip=${data.zipcode}`, {
    method: 'GET'
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

class guaranteepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zipcodeError: false,
      containerSize : "",
      length: 0,
      width: 0,
      hight: 0,
      err: {},
      tab: 'dumpster',
      tons: null,
      live_load_yard: '',
      requestedTime: '',
      requestedDate: '',
      deliveryday: '',
      email: '',
      containerlocation: '',
      placement: '',
      otherPlacement: '',
      streetaddress: "",
      address: '',
      neighborhood: "",
      borough: "",
      city: "",
      zipcode: "",
      state: "",
      lat: 0,
      lng: 0,
      addressDisabled: true,
      cityname: "New York",
      place: "New York",
      cityForPhone: '',
      dumpstertype: "garbage"
    };
  }

  componentDidMount = async() => {
    const searchParam = (_.get(this.props, 'location.search', '')).replace('?=', '').trim()
    const gclid = _.get(this.props.location, 'search', '').replace('?gclid=', '')
    if(gclid && gclid !== '') {
      localStorage.setItem('gclid', gclid)
    } else {
      localStorage.removeItem('gclid')
    }
    if(searchParam && searchParam.length && gclid === '') {
      navigate(`/curbside-now/quote/${searchParam}`)
    }
    let url = window && window.location.pathname.split('/')
    this.setState({ url: window && window.location.pathname })
    if(url.length === 2 || url.length === 3 || url.length === 4 || url.length === 5) {
      let p = url[2] === "new-york" ? "NewYork" : url[2]
      if(p === undefined) {
        p = ""
      }
      this.setState({ cityForPhone: p })
      const fetchInfo = () => axios.get(`${HOSTNAME}/api/sessions/getZipData?place=${p}`)
      const res = await fetchInfo()
      if(res && res.data && res.data.data) {
        const allData = _.cloneDeep(res.data.data)
        const arr = _.get(res.data, 'data', [])
        let obj = {}
        const half = parseInt(arr.length/2)
        var b = arr.splice(0,half);
        var a = arr
        const shalf = parseInt(b.length/2)
        var c = b.splice(0,shalf);
        const thalf = parseInt(a.length/2)
        var d = a.splice(0,thalf);
        obj = {
          a: c,
          b: b,
          c: d,
          d: a
        }
        if((url.length === 4 || url.length === 5 ) && url[3] !== "") {

          let selected = _.find(allData, (r) => {
            return _.lowerCase(r.cityname).replace(/\s/g, '') === url[3] && _.lowerCase(url[3]).replace(/\s/g, '')
          })
          if(selected) {
            this.setState({ cityname:  _.get(selected, 'cityname', ''), selected, place:_.get(selected, 'place', '') })
          } else {
            this.setState({ cityname: _.get(res.data, 'data[0].place', ''), place: _.get(res.data, 'data[0].place', '')})
          }
        }
        else {
          this.setState({ cityname: _.get(res.data, 'data[0].place', ''), place: _.get(res.data, 'data[0].place', '')})
        }
        if(p === "") {
          this.setState({ cityname: "New York", place: "New York"})
        }
        this.setState({ cityListing: obj })
      }
    }

    // (function () {
    //   var d = document;
    //   var s = d.createElement("script");
    //   s.type = "text/javascript"
    //   //s.src = "https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyDC4hfVQUrN7Vc45Wh0Qpx4cpxs1YPWE24&libraries=places";
    //   s.src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDC4hfVQUrN7Vc45Wh0Qpx4cpxs1YPWE24&libraries=places"
    //   // d.getElementsByTagName.appendChild(s);
    //   d.getElementsByTagName("head")[0].appendChild(s);
    //   //d.getElementById("calendly1")[0].appendChild(s);
    // })();

    const self = this
      setTimeout(function(){
      if (window.google){
        self.setState({ load: true },()=>{
          self.forceUpdate()
        })
      }
    }, 2000);

    if (_.get(this.props, 'location.state.tab', '')) {
      let containerSize = ''
      if (_.get(this.props, 'location.state.tab', '') === "Live Load") {
        containerSize = "Live Load"
      }
      this.setState({ tab: _.get(this.props, 'location.state.tab', ''), containerSize })
    }
  }

  update() {
    this.componentDidMount()
  }

  changeTab (tab) {
    let containerSize = ''
    this.setState({ tab },()=>{
      if (this.state.tab === "Live Load") {
        containerSize = "Live Load"
      }
      this.setState({ containerSize, live_load_yard: ''  })
    })
  }

  handleChange (e) {
    let length = 0
    let width = 0
    let hight = 0

    if (e.target.value === "10 Yard") {
      length = 11
      width = 8
      hight = 4
    }
    if (e.target.value === "15 Yard") {
      length = 16
      width = 8
      hight = 4
    }
    if (e.target.value === "20 Yard") {
      length = 22
      width = 8
      hight = 4
    }
    if (e.target.value === "30 Yard") {
      length = 22
      width = 8
      hight = 6
    }
    console.log(e.target.name, )
    if(e.target.value) {
      const err = this.state.err
      err[e.target.name] = ''
      this.setState({ err })
    }
    this.setState({ [e.target.name]: e.target.value, hight, width, length },()=>{

    })
  }

  onDateChange(date, dateString) {
    if (date && dateString) {
      let deliveryday = this.getDay(date._d.getDay())
      const err = this.state.err
      err.requestedDate = ''
      this.setState({ err, requestedDate: dateString, deliveryday },()=>{
      })
    }
  }

  onDateChangeforMobile (e) {
    if (e.target.value) {
      var d = new Date( e.target.value );
      let date = moment(d).format("MM/DD/YYYY")
      let day = d.getDay();
      let deliveryday = this.getDay(day)
      const err = this.state.err
      err.requestedDate = ''
      this.setState({ err, requestedDate: date, deliveryday },()=>{
      })
    } else {
      this.setState({ requestedDate: '', deliveryday: '' },()=>{
      })
    }
  }
  getDay(day){
    return weekdays[day];
  }

  onChange(e) {
    if(e.target.value) {
      const err = this.state.err
      err[e.target.name] = ''
      this.setState({ err })
    }
    this.setState({ [e.target.name]: e.target.value },()=>[
    ])
  }

  onChangeAddress(value) {
    this.setState({ streetaddress: '' })
  }

  getTons () {
    let tons = null
    if (this.state.containerSize === "10 Yard") {
      tons = 2
    }
    if (this.state.containerSize === "15 Yard") {
      tons = 3
    }
    if (this.state.containerSize === "20 Yard") {
      tons = 4
    }
    if (this.state.containerSize === "30 Yard") {
      tons = 6
    }
    this.setState({ tons })
    return tons
  }

  validate() {
    let errObj = {}
    const { email, requestedDate, requestedTime, live_load_yard, placement, otherPlacement, addressDisabled, zipcodeError, tab, containerSize, containerlocation } = this.state
    let scrollID = ''
    if (email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) { // eslint-disable-line
        errObj.email = true
      }
    }
    if(addressDisabled === true || zipcodeError) {
      errObj.address = true
      scrollID = scrollID === '' ? 'address' : scrollID
    }
    if(tab === 'dumpster') {
      if(!containerSize || containerSize === '') {
        errObj.containerSize = true
        scrollID = scrollID === '' ? 'containerSize' : scrollID
      }
      if(!placement || placement === '') {
        errObj.placement = true
        scrollID = scrollID === '' ? 'placement' : scrollID
      }
    } else {
      if(!live_load_yard || live_load_yard == '') {
        errObj.live_load_yard = true
        scrollID = scrollID === '' ? 'live_load_yard' : scrollID
      }
      if(!placement || placement === '') {
        errObj.placement = true
        scrollID = scrollID === '' ? 'placement' : scrollID
      }
    }
    if(placement === 'On Street' && _.get(this.state, 'containerlocation', '') === '' ) {
      errObj.containerlocation = true
      scrollID = scrollID === '' ? 'containerlocation' : scrollID
    }
    if(placement === 'Other' && _.get(this.state, 'otherPlacement', '') === '' ) {
      errObj.otherPlacement = true
      scrollID = scrollID === '' ? 'otherPlacement' : scrollID
    }
    if(!requestedDate || requestedDate === '') {
      errObj.requestedDate = true
      scrollID = scrollID === '' ? 'requestedDate' : scrollID
    }
    if(!requestedTime || requestedTime === '') {
      errObj.requestedTime = true
      scrollID = scrollID === '' ? 'requestedTime' : scrollID
    }
    if(email === "") {
      errObj.email = true
      scrollID = scrollID === '' ? 'email' : scrollID
    }
    console.log(errObj, 'errObj')
    this.setState({ err: errObj, scrollID },()=>{
      this.forceUpdate()
    })
  }
  randomString16(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  handleEventCall = ()=> {
    if(process.env.REACT_APP_GA) {
    ReactGA.pageview(ReactGA.pageview(_.get(this.props, 'location.pathname', '/')))
      ReactGA.event({
        category: 'Home inquiries',
        action: 'Home inquiries'
      })
    }
  }
  handleEventCallPhone() {
    if(process.env.REACT_APP_GA) {
      ReactGA.pageview(ReactGA.pageview(_.get(this.props, 'location.pathname', '/')))
      ReactGA.event({
        category: 'Clicks to the phone number',
        action: 'Clicks to the phone number'
      })
    }
  }
  getPrice = async ()=> {
    await this.validate()
    let randomString = await this.randomString()
    const { err, scrollID } = this.state
    var btnSubmitTags = document.getElementById('getaprice' );
    // btnSubmitTags.click();
    if(Object.keys(err).length === 0) {
      this.handleEventCall()
      const { containerSize, tab, live_load_yard, requestedDate, requestedTime, deliveryday, email, zipcode, containerlocation, placement, otherPlacement,
        address, streetaddress, city, state, county, lat, lng, borough, neighborhood,
      } = this.state
      let gclid = localStorage.getItem('gclid')
      gclid = gclid && gclid !== '' ? gclid : ''
      let dataRedirect = {
        gclid,
        tab,
        containerSize, live_load_yard, tons: this.getTons(), requestedDate, requestedTime, deliveryday, email, zipcode, containerlocation, placement, otherPlacement,
        address, streetaddress, city, state, county, lat, lng, borough, neighborhood, email, randomString
      }
      const mailCredential = {
        date_of_submission: new Date(),
        address: address,
        email: email.toLowerCase(),
        gclid,
        requestedDate: requestedDate,
        url: String(document.location),
        type: "home",
        randomString,
        dataState: dataRedirect,
        quoteUrl: `https://mycurbside.com/curbside-now?=${randomString}`
      }
      await fetch(`${HOSTNAME}/api/quotes/insertHomeCustomer`,{
        method:'POST',
        headers:{
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mailCredential)
      }).then((res) =>  {
        return res.json()
      }).then((data) => {
        if(data.error) {
          if(data && data.error && data.error.statusCode && data.error.statusCode === 400 ){
            this.setState({ openModal: true })
          } else {
            message.error(err && err.message ? err.message : "")
          }
        } else {
          btnSubmitTags.click();
          navigate(`/curbside-now/quote/${randomString}`,
          {
            state: {
              data: dataRedirect,
            },
          }
        );
        }
      }).catch((err) => {
        console.log(err, 'errerr')
        message.error(err && err.message ? err.message : "")
      });
    } else {
      const element = document.getElementById(`${scrollID}`)
      if(element) {
        const elemHeight = document.getElementById(`${scrollID}`).offsetTop;
        console.log(elemHeight, elemHeight)
        window.scroll(0,
          elemHeight);
      }
    }
  }

  randomString() {
    const length = 6
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  closeModal() {
    this.setState({ openModal: false })
  }
  buttonDisabled (){
    let disable = true
    const { containerSize, requestedDate, requestedTime, live_load_yard, zipcode, placement, otherPlacement, containerlocation, addressDisabled, zipcodeError, email } = this.state
    if (containerSize && containerSize === "Live Load") {
      if (live_load_yard && containerSize && requestedDate && requestedTime && zipcode &&  placement && !zipcodeError && email) {
        disable = false
      }
    } else {
      if (containerSize && requestedDate && requestedTime && zipcode && placement && !zipcodeError && email) {
        disable = false
      }
    }
    if (placement && placement === "Other") {
      if (!otherPlacement) {
        disable = true
      }
    }
    if (placement && placement === "On Street") {
      if (!containerlocation) {
        disable = true
      }
    }
    if(addressDisabled === true) {
      disable = true
    }
    return disable
  }

  topScroll () {
    window && window.scrollTo(0,0)
  }

  onSuggestSelect(suggest) {
    let state = ''
    let city = ''
    let zipcode = ''
    let streetaddress = ''
    let country = ''
    let streetNo = ''
    let displayaddress = ""
    let neighborhood = ""
    let borough = ""
    let lat = 0
    let lng = 0
    let address= '' //eslint-disable-line
    if (suggest) {
      if (suggest.location.lat && suggest.location.lng) {
        lat = suggest.location.lat
        lng = suggest.location.lng
      }
     // address = suggest.description
      suggest.gmaps.address_components.forEach(function (element) {
       // displayaddress = suggest.gmaps.formatted_address.replace(', USA', '')
      element.types.forEach(function (type) {
        switch (type) {
          case 'administrative_area_level_1':
            state = element.short_name;
            break;
          case 'postal_code':
            zipcode = element.long_name;
            break;
          case "locality":
            city = element.long_name;
            break;
          case 'street_number':
            streetNo = element.long_name;
            break;
          case 'country':
            country = element.short_name;
            break;
          case 'sublocality_level_1':
            borough = element.long_name;
            break;
          case 'neighborhood':
            neighborhood = element.long_name;
            break;
          default:
          break;
        }
       }, this)
      }, this)
      if(zipcode) {
       fetch(`${HOSTNAME}/api/sessions/checkzip?zip=${zipcode}`,{
         method:'GET'
       }).then((res) =>  {
         return res.json()
       }).then((data) => {
         if(data.error) {
           this.setState({ errModal: true, addressDisabled: true, zipcodeError: true })
         } else {
           const err = this.state.err
           err.address = ''
           let fullAddress = suggest.gmaps.formatted_address
           let addArr = [];
           let streetAdd = [];
           addArr = fullAddress.split(', ')
           streetAdd = suggest.description.split(', ')
           if(streetAdd.length > 0) {
             streetaddress = streetAdd[0].trim()
           }
           if (city === "") {
             if(addArr.length > 0) {
               if(addArr.length === 4) {
                 city = addArr[1]
               } else if(addArr.length === 5) {
                 city = addArr[2]
               }
             }
           }
           displayaddress = streetaddress ? `${streetaddress}, ` : ''
           displayaddress += city ? `${city}, ` : ''
           displayaddress += state ? `${state} ` : ''
           displayaddress += zipcode ? `${zipcode}` : ''
           this.setState({addressDisabled: false, zipcodeError: false, err, zoom: 15, addressDisabled: false, county: _.get(data, 'data[0].place'), address: displayaddress, streetNo, streetaddress, displayaddress, city, state, zipcode, country, lat, lng, borough, neighborhood },()=>{
             // this.getStateValuesforHeader()
           })
         }
       })
     }
    }
  }
  onSuggestNoResults(userInput) {
  //console.log('onSuggestNoResults for :' + userInput); // eslint-disable-line
  }

  minDateForMobile(){
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();
    if(dd<10){
          dd='0'+dd
      }
      if(mm<10){
          mm='0'+mm
      }
    today = yyyy+'-'+mm+'-'+dd;
    return today
  }
  _onFocus(e){
    e.target.type = "date";
  }
  _onBlur(e){
      if (this.state.requestedDate){
        e.target.type = "date";
      } else {
        e.target.type = "text";
      }
      e.currentTarget.placeholder = "When would you like your dumpster dropped off?";
  }
  getPriceGa() {
    console.log('event---')
  }

  selectType(type) {
    this.setState({dumpstertype: type})
  }
  render() {
    const { cityname, cityListing, selected, err, place, url } = this.state
    if(url === "/curbside-now/new-york" || url === "/curbside-now/new-york/") {
      document.title = "New York City Debris Removal | Curbside"
    }
    return (
      <div>
        <Header/>
        <section className="posi-rel">
          <img src={guaranteeBanner} alt="" />
          <div className="boxwhite">
            <img src={BackedIcon} alt="" />
            <h2>Curbside Price Guarantee</h2>
            <p>Curbside has been serving our community for over four years and we take pride in doing this differently. We use custom technology and developed a lot of efficiencies that other companies in our industry don’t have. We pass on the savings to you, our customers.</p>
            <a href="" className="learnmore">Learn More</a>
          </div>
        </section>
        <div className="clearfix"/>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="priceless">
                  <h3>Found a price for less? Here's what you do:</h3>
                  <p>Connect with us via Cubside <a href="">chat</a> or email <a href="">hello@mycurbside.com</a>. Please be prepared to direct us to the quote or website that is displaying the current lower price you would like to match. You can also call  <a href="" className="full-anchor">(718-384-6357)</a>.</p>
                
                  <div className="form-group">
                    <div className={this.state.dumpstertype === "dumpster" ? "box activebox" : "box"} onClick={this.selectType.bind(this, 'dumpster')}>
                      <div className="img__box"><img src={dumpsterImgOne} alt="" /></div>
                      <div className="text__box">
                        <h4>I need a dumpster</h4>
                        <p>We will deliver the dumpster where you need it. When it is full and ready, we will pick it up!</p>
                      </div>
                    </div>
                    <div className={this.state.dumpstertype === "garbage" ? "box activebox" : "box"} onClick={this.selectType.bind(this, 'garbage')}>
                      <div className="img__box"><img src={dumpsterImgTwo} alt="" /></div>
                      <div className="text__box">
                        <h4>I need a garbage truck</h4>
                        <p>We will send our garbage truck to pick up whatever you need upon arrival.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>


      

        <section className="neighbourhoods">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Neighborhoods We Service</h1>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <ul>
                  {_.get(cityListing, 'a', []).map((c, i)=>{
                    return (
                      <li key={i} onClick={this.update.bind(this)}><a href={`/curbside-now/${_.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'') == "newyork" ? `new-york?type=${this.state.dumpstertype}` : _.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'')}/${_.lowerCase(c.cityname.replace(/\s/g, '')).replace(/\s/g,'')}?type=${this.state.dumpstertype}`}>{_.get(c, 'cityname', '')}</a></li>
                    )
                  })}
                </ul>
                <ul>
                  {_.get(cityListing, 'b', []).map((c, i)=>{
                    return (
                      <li key={i} onClick={this.update.bind(this)}><a href={`/curbside-now/${_.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'') == "newyork" ? `new-york?type=${this.state.dumpstertype}` : _.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'')}/${_.lowerCase(c.cityname.replace(/\s/g, '')).replace(/\s/g,'')}?type=${this.state.dumpstertype}`}>{_.get(c, 'cityname', '')}</a></li>
                    )
                  })}
                </ul>
                <ul>
                  {_.get(cityListing, 'c', []).map((c, i)=>{
                    return (
                      <li key={i} onClick={this.update.bind(this)}><a href={`/curbside-now/${_.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'') == "newyork" ? `new-york?type=${this.state.dumpstertype}` : _.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'')}/${_.lowerCase(c.cityname.replace(/\s/g, '')).replace(/\s/g,'')}?type=${this.state.dumpstertype}`}>{_.get(c, 'cityname', '')}</a></li>
                    )
                  })}
                </ul>
                <ul>
                  {_.get(cityListing, 'd', []).map((c, i)=>{
                    return (
                      <li key={i} onClick={this.update.bind(this)}><a href={`/curbside-now/${_.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'') == "newyork" ? `new-york?type=${this.state.dumpstertype}` : _.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'')}/${_.lowerCase(c.cityname.replace(/\s/g, '')).replace(/\s/g,'')}?type=${this.state.dumpstertype}`}>{_.get(c, 'cityname', '')}</a></li>
                    )
                  })}
                </ul>
              </div>
            </div>

          </div>
          <ReactModal
            isOpen={this.state.openModal}
            onRequestClose={this.closeModal.bind(this)}
            contentLabel=""
            ariaHideApp={false}
            className="paymentlogin"
            >
              <div className="react-modal-dialog react-modal-dialog-400 react-modal-dialog-centered">
                <div className="react-modal-header">
                  {/* <h5 className="react-modal-title"></h5> */}
                <button type="button" className="btn react-modal-close" onClick={this.closeModal.bind(this)}><img src={modalcloseIcon} alt="" /></button>
                </div>
                <div className="react-modal-body">
                  <div className="wrapps">
                    <h4>You already have an account, please login to you portal to place an order.</h4>
                    <a href={LOGIN_URL} target="_blank"><button className="login-btn">Login</button></a>
                  </div>
                </div>
            </div>
          </ReactModal>
        </section>

        <section className="graybg transpbg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>How Curbside Works</h2>
                <p>Get Started in three easy steps. We have the technology to keep things as efficient and sustainable as possible to help your project run smoothly.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul>
                  <li>
                    <img src={WorkOne} alt="" />
                    <h5>Select & Schedule Service</h5>
                    <p>Select the day and time for your debris removal and get instant, affordable pricing. </p>
                  </li>
                  <li>
                    <img src={WorkTwo} alt="" />
                    <h5>Manage Everything Online</h5>
                    <p>Contacting the Cubside team, and viewing orders are a tap away.</p>
                  </li>
                  <li>
                    <img src={WorkThree} alt="" />
                    <h5>Sit Back and Relax</h5>
                    <p>The Curbside driver will pickup and drop off your removal on time.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

      </div>
    );
  }
}

export default guaranteepage;
